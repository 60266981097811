import { Option, TimeFilterValue, TimePeriod } from '@hakimo-ui/hakimo/types';
import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { allTimePeriodOptions } from '../constants';

interface Props {
  value: TimeFilterValue;
  onChange: (value: TimeFilterValue) => void;
}

function TimeFilter(props: Props) {
  const { value, onChange } = props;
  const periodOptions = allTimePeriodOptions.filter(
    (opt) => opt.name !== TimePeriod.CUSTOM_TIME
  );
  const selected = periodOptions.find((item) => item.name === value.period);

  const onChangeTimePeriod = (option: Option<TimePeriod>) => {
    onChange({
      period: option.name,
      customRangeStart: '',
      customRangeEnd: '',
    });
  };

  const onChangeCustomRangeStart: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    // Update end date also if it is not set to make it easier for the user
    let newEnd = '';
    if (value.customRangeEnd === '') {
      newEnd = event.target.value;
    } else {
      newEnd = value.customRangeEnd;
    }
    onChange({
      ...value,
      customRangeStart: event.target.value,
      customRangeEnd: newEnd,
    });
  };

  const onChangeCustomRangeEnd: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    onChange({
      ...value,
      customRangeEnd: event.target.value,
    });
  };

  return (
    <div>
      <SelectMenu
        label="Period"
        items={periodOptions}
        value={selected}
        onChange={onChangeTimePeriod}
        displayValue={(item) => (item ? item.name : '--Select--')}
      />
      {selected?.name === TimePeriod.CUSTOM_RANGE && (
        <div className="space-y-1">
          <label className="block">
            <span className="dark:text-dark-text text-xs text-gray-700">
              Start Date
            </span>
            <input
              type="datetime-local"
              className="dark:bg-dark-bg mt-1 block w-full rounded border-gray-400 text-sm dark:border-gray-600 dark:text-white dark:[color-scheme:dark]"
              value={value.customRangeStart}
              onChange={onChangeCustomRangeStart}
            />
          </label>
          <label className="block">
            <span className="dark:text-dark-text text-xs text-gray-700">
              End Date
            </span>
            <input
              type="datetime-local"
              className="dark:bg-dark-bg mt-1 block w-full rounded border-gray-400 text-sm dark:border-gray-600 dark:text-white dark:[color-scheme:dark]"
              value={value.customRangeEnd}
              onChange={onChangeCustomRangeEnd}
            />
          </label>
        </div>
      )}
    </div>
  );
}

export default TimeFilter;
